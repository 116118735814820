import React from 'react'
import type {
  GatsbyBrowser,
  GatsbySSR,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
  WrapRootElementBrowserArgs,
  WrapRootElementNodeArgs,
} from 'gatsby'

import { Layout } from './src/components/layout'

type RootWrapper = GatsbyBrowser['wrapRootElement'] | GatsbySSR['wrapRootElement']
type PageWrapper = GatsbyBrowser['wrapPageElement'] | GatsbySSR['wrapPageElement']

// Set up any Provider components that will wrap the application.
export const rootWrapper: RootWrapper = ({
  element,
}: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => <>{element}</>

// Set wrapper components around pages that won’t get unmounted on page changes.
export const pageWrapper: PageWrapper = ({
  element,
  props,
}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs) => <>{element as any}</>
